import React from 'react';
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  DialogContentText,
  InputAdornment,
} from '@mui/material';
import { Add, ArrowBack } from '@mui/icons-material';
import {
  formatNumber,
  calculateSprintTotal,
  calculateGameTotal,
} from '../utils';

export const ActionButtons = ({ handleAddPlayer, handleStartNewGame }) => (
  <Box className="action-buttons">
    <Box className="add-player-container">
      <IconButton onClick={handleAddPlayer} className="add-button">
        <Add />
      </IconButton>
      <Typography className="add-label">Add Player</Typography>
    </Box>
    <Box className="start-new-game-container">
      <IconButton onClick={handleStartNewGame} className="add-button">
        <Add />
      </IconButton>
      <Typography className="add-label">Start New Game</Typography>
    </Box>
  </Box>
);

export const GameTotals = ({ players }) => {
  // Sort players by their total score in descending order
  const sortedPlayers = [...players].sort((a, b) => 
    calculateGameTotal(b.scores) - calculateGameTotal(a.scores)
  );

  return (
    <Box className="game-total-container">
      <div className="gametotal-spacer"></div>
      <TableContainer component={Paper}>
        <Table className="game-total-table">
          <TableHead>
            <TableRow>
              <TableCell>Player</TableCell>
              <TableCell align="right">Game Totals</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPlayers.map((player) => (
              <TableRow key={player.id}>
                <TableCell component="th" scope="row" style={{ fontWeight: 700 }}>
                  {player.name}
                </TableCell>
                <TableCell align="right" className="table-cell">
                  {formatNumber(calculateGameTotal(player.scores))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const PlayerComponents = ({
  players,
  currentSprint,
  handlePlayerNameClick,
  handleClickOpen,
  handleBackToSprints,
}) => (
  <>
    <Button onClick={handleBackToSprints} startIcon={<ArrowBack />}>
      Back to Sprints
    </Button>
    <Typography variant="h5" component="h3" className="sprint-header">
      Sprint {currentSprint + 1} Details
    </Typography>
    <TableContainer component={Paper}>
      <Table className="sprint-table">
        <TableHead>
          <TableRow>
            <TableCell>Player</TableCell>
            <TableCell align="right">Acquired Users</TableCell>
            <TableCell align="right">Bonus Users</TableCell>
            <TableCell align="right">Sprint Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {players.map((player, playerIndex) => (
            <TableRow key={player.id}>
              <TableCell component="th" scope="row" onClick={(event) => handlePlayerNameClick(playerIndex, event)}>
                {player.name}
              </TableCell>
              <TableCell align="right" className="table-cell" onClick={() => handleClickOpen(playerIndex, 'acquiredUsers')}>
                {formatNumber(player.scores[currentSprint]?.acquiredUsers || 0)}
              </TableCell>
              <TableCell align="right" className="table-cell" onClick={() => handleClickOpen(playerIndex, 'bonusUsers')}>
                {formatNumber(player.scores[currentSprint]?.bonusUsers || 0)}
              </TableCell>
              <TableCell align="right" className="table-cell">
                {formatNumber(calculateSprintTotal(player.scores, currentSprint))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

export const Dialogs = ({
  openDialog,
  handleDialogClose,
  dialogValue,
  handleDialogChange,
  handleDialogSave,
  currentField,
  isMobile,
  openConfirmDialog,
  handleConfirmDialogClose,
  handleConfirmDialogOk,
  handleConfirmDialogCancel,
  showGameEndDialog,
  setShowGameEndDialog,
  isExtendedGame,
  gameWinner,
  handleEndGame,
  handleContinueGame,
  showWinnerDialog,
  handleCloseWinnerDialog,
}) => (
  <>
    <Dialog open={openDialog} onClose={handleDialogClose}>
      <DialogTitle>
        Edit {currentField === 'acquiredUsers' ? 'Acquired Users' : currentField === 'bonusUsers' ? 'Bonus Users' : 'Player Name'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the new value for {currentField === 'acquiredUsers' ? 'Acquired Users' : currentField === 'bonusUsers' ? 'Bonus Users' : 'Player Name'}.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={currentField === 'acquiredUsers' ? 'Acquired Users' : currentField === 'bonusUsers' ? 'Bonus Users' : 'Player Name'}
          type={currentField !== 'name' ? (isMobile ? 'tel' : 'number') : 'text'}
          fullWidth
          value={dialogValue}
          onChange={handleDialogChange}
          onBlur={handleDialogSave}
          InputProps={{
            endAdornment: currentField !== 'name' ? <InputAdornment position="end">,000</InputAdornment> : null,
          }}
          InputLabelProps={{ shrink: true }} // This line ensures the label is always visible
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDialogSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose}>
      <DialogTitle>Confirm Navigation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Navigating away to the home page will mark the game as done. You can view the game in the Past Game page.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmDialogCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirmDialogOk} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={showGameEndDialog} onClose={() => setShowGameEndDialog(false)}>
      <DialogTitle>Game Ended</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isExtendedGame ? `The extended game has ended. Winner: ${gameWinner}.` : `The normal game has ended. Winner: ${gameWinner}.`}
          {isExtendedGame ? '' : ' Do you want to continue to the extended game?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isExtendedGame ? (
          <Button onClick={handleEndGame} color="primary">
            OK
          </Button>
        ) : (
          <>
            <Button onClick={handleEndGame} color="primary">
              End Game
            </Button>
            <Button onClick={handleContinueGame} color="primary">
              Continue to Extended Game
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
    <Dialog open={showWinnerDialog} onClose={handleCloseWinnerDialog}>
      <DialogTitle>Winner</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The winner is: {gameWinner}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseWinnerDialog} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

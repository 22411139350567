import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import Header from './Header';
import Footer from './Footer'; // Import the Footer component

const HomePage = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/guestgame');
  };

  return (
    <div className="home-page">
      <div className="welcome-container">
        <h1>Welcome to the App Wars e-scorecard!</h1>
        <p>This is a companion app to track the completed Sprints played for the ultra-fun battle card game called App Wars.</p>
        <p>Acquire Users, battle opponents, force a situation on other players and protect yourself while acquiring the most users to WIN! Don’t forget to have fun!</p>
        <p>To understand how to play each card, refer to the card guide <a href='https://theappwars.com/#search-spacer'>HERE</a></p>
        <button onClick={handleNavigate} className="play-button">Click to Track Scores</button>
      </div>
      <div className="how-to-play">
        <h2>How to Use This App</h2>
        <ol>
          <li>Click the button above to open the e-scorecard app</li>
          <li>In each Sprint, click on the Acquired/Bonus Users fields for each player and enter their scores. See the guide below.</li>
          <li>To add more players to your game, click +Add Players.</li>
          <li>Continue to play Sprints and come back to this e-scorecard to track each player's total users.</li>
          <li>To add more Sprints to your current game, click on +New Sprint.</li>
          <li>The Game Totals on the top of the page show the total from all the completed Sprints for each player from the scores you entered.</li>
          <li>Remember, to win the game a player needs 3 million (normal game) and 5 million (extended game) total users.</li>
          <li>Be aware that by clicking +Start a New Game, all Sprints totals will reset to zero for all players.</li>
        </ol>
      
        <h2>e-Scorecard Guide</h2>
        Tally up the total users from each player's User Base for each completed Sprint:
        <h3>For Acquired Users:</h3>
        <ul>
          <li>User cards and Award cards played in User Base.</li>
        </ul>
        <h3>For Bonus Users(if any):</h3>
        <ul>
          <li>Awarded Bonus Users Trackers in User Base.</li>
          <li>Add 400K Bonus Users if a player completes a Sprint with exactly 1 million "acquired users".</li>
          <li>Add 400K Bonus Users if a player has all 4 Safety cards played in their User Base.</li>
          <li>Double all Bonus Users for the current Sprint if a player has the Marketing Win card played in their User Base.</li>
        </ul>
      </div>
      <Footer /> {/* Add the Footer component here */}
    </div>
  );
};

export default HomePage;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { getGameDetails } from '../firebaseFunctions';
import './GameDetails.css'; // Import the CSS file

const GameDetails = () => {
  const { gameId } = useParams();
  const [game, setGame] = useState(null);

  useEffect(() => {
    const fetchGameDetails = async () => {
      const gameData = await getGameDetails(gameId);
      setGame(gameData);
    };

    fetchGameDetails();
  }, [gameId]);

  if (!game) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="landing-container">
      <Box className="landing-header">
        <Typography variant="h4" component="h1" gutterBottom>
          Game Details
        </Typography>
        <Box>
          <Typography variant="h6" component="h2">
            Players
          </Typography>
          <List>
            {game.players.map((player, index) => (
              <ListItem key={index} className="past-game-item">
                <ListItemText
                  primary={player.name}
                  secondary={
                    <React.Fragment>
                      {player.scores.map((score, scoreIndex) => (
                        <Typography key={scoreIndex} component="span">
                          Sprint {scoreIndex + 1} - Acquired Users: {score.acquiredUsers}, Bonus Users: {score.bonusUsers}
                          <br />
                        </Typography>
                      ))}
                    </React.Fragment>
                  }
                  className="past-games-text"
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Container>
  );
};

export default GameDetails;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, ThemeProvider, createTheme, Box, Grid, Typography, TableContainer,
  Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Snackbar,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, InputAdornment,
  List, ListItem, ListItemText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add, Remove } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { saveGame, loadGame, getIncompleteGame } from '../firebaseFunctions';
import { GameTotals, PlayerComponents } from './Components';
import {
  calculateSprintTotal, formatNumber, calculateGameTotal, getRandomName,
} from '../utils';
import './GuestGame.css';

const theme = createTheme({
  // palette: {
  //   primary: { main: '#AADBE1' },
  //   secondary: { main: '#E0F8D2' },
  // },
  typography: {
    fontFamily: 'Roboto Condensed, Arial, sans-serif',
    h5: { fontWeight: 700 },
    body1: { color: '#484848' },
  },
  components: {
    MuiButton: {
      styleOverrides: { root: { borderRadius: '8px', textTransform: 'uppercase' } },
    },
    MuiTableCell: {
      styleOverrides: {
        root: { padding: '12px 16px' },
        // head: { backgroundColor: '#E0F8D2', color: '#ffffff' },
        body: { color: '#ffffff' },
      },
    },
    MuiPaper: {
      styleOverrides: { root: { borderRadius: '15px', textTransform: 'uppercase', backgroundColor: 'rgba(255, 255, 255, 1)' } },

    },
  },
});

const GuestGame = () => {
  const [players, setPlayers] = useState([]);
  const [sprints, setSprints] = useState([{ index: 0 }, { index: 1 }]);
  const [currentSprint, setCurrentSprint] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogValues, setDialogValues] = useState({ acquiredUsers: '', bonusUsers: '' });
  const [currentField, setCurrentField] = useState('');
  const [currentEditingPlayer, setCurrentEditingPlayer] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [showGameEndDialog, setShowGameEndDialog] = useState(false);
  const [isExtendedGame, setIsExtendedGame] = useState(false);
  const [gameWinner, setGameWinner] = useState('');
  const [showWinnerDialog, setShowWinnerDialog] = useState(false);
  const [shouldAssessWinner, setShouldAssessWinner] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [gameId, setGameId] = useState('');
  const [gameEndDialogShown, setGameEndDialogShown] = useState(false);
  const [openNewGameConfirmDialog, setOpenNewGameConfirmDialog] = useState(false);
  const [openConfirmEndGameDialog, setOpenConfirmEndGameDialog] = useState(false);
  const [openIncompleteGameDialog, setOpenIncompleteGameDialog] = useState(false);
  const [incompleteGameData, setIncompleteGameData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkForIncompleteGame = async () => {
      console.log('Checking for incomplete games...');
      const incompleteGame = await getIncompleteGame();
      console.log('Incomplete game:', incompleteGame);
      if (incompleteGame) {
        setIncompleteGameData(incompleteGame);
        setOpenIncompleteGameDialog(true);
      } else {
        const initialPlayers = Array.from({ length: 3 }, (_, index) => ({
          id: `player${index}`,
          name: getRandomName(),
          scores: Array(2).fill({ acquiredUsers: 0, bonusUsers: 0 }),
        }));
        const newGameId = `AppWars-${new Date().toLocaleString().replace(/[/,:\s]/g, '.')}`;
        setPlayers(initialPlayers);
        setSprints([{ index: 0 }, { index: 1 }]);
        setGameId(newGameId);
        console.log('Initialized new game state:', { newGameId, initialPlayers, sprints: [{ index: 0 }, { index: 1 }] });
      }
    };

    checkForIncompleteGame();

    const handleBeforeUnload = () => {
      saveGameStateToFirebase();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const loadIncompleteGame = () => {
    if (incompleteGameData) {
      const { players, sprints, gameId, gameEnded, gameEndDialogShown } = incompleteGameData;
      setPlayers(players);
      setSprints(sprints);
      setGameId(gameId);
      setGameEnded(gameEnded);
      setGameEndDialogShown(gameEndDialogShown);
      setOpenIncompleteGameDialog(false);
      console.log('Loaded incomplete game state from Firebase:', { players, sprints, gameId, gameEnded, gameEndDialogShown });
    }
  };
  const markGameAsEnded = async (gameData) => {
    if (gameData) {
      const updatedGameData = {
        ...gameData,
        isFinished: true,
        gameEnded: true,
      };
      await saveGame(updatedGameData);
      console.log('Marked current game as ended:', updatedGameData);
    } else {
      console.log('No game data to mark as ended');
    }
  };
  
  

  const startNewGame = async () => {
    console.log('Start New Game button clicked');
    
    if (incompleteGameData) {
      console.log('Incomplete game found, marking as ended...');
      await markGameAsEnded(incompleteGameData);  // Mark the incomplete game as ended
    }
  
    const initialPlayers = Array.from({ length: 3 }, (_, index) => ({
      id: `player${index}`,
      name: getRandomName(),
      scores: Array(2).fill({ acquiredUsers: 0, bonusUsers: 0 }),
    }));
    const newGameId = `AppWars-${new Date().toLocaleString().replace(/[/,:\s]/g, '.')}`;
    setPlayers(initialPlayers);
    setSprints([{ index: 0 }, { index: 1 }]);
    setGameId(newGameId);
    setGameEnded(false);
    setGameEndDialogShown(false);
    setOpenIncompleteGameDialog(false);
    console.log('Started a new game state:', { newGameId, initialPlayers, sprints: [{ index: 0 }, { index: 1 }] });
  };
  
  

  useEffect(() => {
    if (players.length > 0) {
      const updatedPlayers = players.map(player => {
        const updatedScores = [...player.scores];
        while (updatedScores.length < sprints.length) {
          updatedScores.push({ acquiredUsers: 0, bonusUsers: 0 });
        }
        return { ...player, scores: updatedScores };
      });
      setPlayers(updatedPlayers);
      console.log('Updated players with correct scores:', updatedPlayers);
    }
  }, [sprints]);

  const saveGameStateToSession = () => {
    const gameState = {
      players,
      sprints,
      gameId,
      gameEnded,
      gameEndDialogShown,
    };
    sessionStorage.setItem('guestGameState', JSON.stringify(gameState));
    sessionStorage.setItem('currentGame', 'true');
    console.log('Saved game state to session storage:', gameState);
  };

  const saveGameStateToFirebase = async () => {
    const gameData = {
      players,
      sprints,
      gameId,
      isFinished: false,
      gameEnded,
      gameEndDialogShown,
    };
    await saveGame(gameData);
    console.log('Saved game state to Firebase:', gameData);
  };

  const handlePlayerNameChange = (index, name) => {
    if (gameEnded) return;
    const updatedPlayers = players.map((player, i) => (i === index ? { ...player, name } : player));
    setPlayers(updatedPlayers);
    saveGameStateToSession();
    saveGameStateToFirebase();
    console.log('Updated player name:', updatedPlayers);
  };

  const handleUpdateScore = (playerIndex, sprintIndex, field, value) => {
    const updatedPlayers = players.map((player, pIndex) => {
      if (pIndex === playerIndex) {
        const updatedScores = player.scores.map((score, sIndex) => {
          if (sIndex === sprintIndex) {
            const updatedScore = { ...score, [field]: value };
            return updatedScore;
          }
          return score;
        });
        return { ...player, scores: updatedScores };
      }
      return player;
    });
    setPlayers(updatedPlayers);
    saveGameStateToSession();
    saveGameStateToFirebase();
    console.log('Updated player scores:', updatedPlayers);
  };

  const handleAddSprint = () => {
    if (gameEnded) return;
    const newSprintIndex = sprints.length;
    const newSprint = { index: newSprintIndex };
    const updatedSprints = [...sprints, newSprint];
    setSprints(updatedSprints);
    const updatedPlayers = players.map(player => ({
      ...player,
      scores: [...player.scores, { acquiredUsers: 0, bonusUsers: 0 }]
    }));
    setPlayers(updatedPlayers);
    saveGameStateToSession();
    saveGameStateToFirebase();
    console.log('Added new sprint:', { updatedSprints, updatedPlayers });
  };

  const handleAddPlayer = () => {
    if (gameEnded) return;
    if (players.length >= 8) {
      setSnackbarOpen(true);
      setSnackbarMessage('Maximum 8 players allowed');
      return;
    }
    const newPlayer = {
      id: `player${players.length}`,
      name: getRandomName(),
      scores: Array(sprints.length).fill({ acquiredUsers: 0, bonusUsers: 0 }),
    };
    setPlayers([...players, newPlayer]);
    saveGameStateToSession();
    saveGameStateToFirebase();
    console.log('Added new player:', newPlayer);
  };

  const formatNumber = (num) => {
    if (isNaN(num)) return '';
    return num.toLocaleString();
  };

  const calculateSprintTotal = (scores, sprintIndex) => {
    const sprint = scores[sprintIndex] || { acquiredUsers: 0, bonusUsers: 0 };
    return (sprint.acquiredUsers || 0) + (sprint.bonusUsers || 0);
  };

  const calculateGameTotal = (scores) => {
    return scores.reduce((total, score) => total + (score.acquiredUsers || 0) + (score.bonusUsers || 0), 0);
  };

  const handleStartNewGame = async () => {
    // First, save the current game as ended
    const endedGameData = {
      players,
      sprints,
      gameId,
      isFinished: true,
      gameEnded: true,
      gameEndDialogShown,
    };

    // Save the ended game to Firebase
    await saveGame(endedGameData);
    console.log('Saved ended game:', endedGameData);

    // Generate a new game ID
    const newGameId = `AppWars-${new Date().toLocaleString().replace(/[/,:\s]/g, '.')}`;

    // Keep the current players, but clear their scores for only 2 sprints
    const resetPlayers = players.map(player => ({
      ...player,
      scores: Array(2).fill({ acquiredUsers: 0, bonusUsers: 0 })
    }));

    // Reset sprints to only 2
    const newSprints = [{ index: 0 }, { index: 1 }];

    // Create new game data
    const newGameData = {
      players: resetPlayers,
      sprints: newSprints,
      gameId: newGameId,
      isFinished: false,
      gameEnded: false,
      gameEndDialogShown: false,
    };

    // Save the new game data
    await saveGame(newGameData);

    // Update local state
    setGameId(newGameId);
    setPlayers(resetPlayers);
    setSprints(newSprints);
    setGameEnded(false);
    setGameEndDialogShown(false);

    // Reset current sprint to null (to show all sprints view)
    setCurrentSprint(null);

    // Save to session storage
    saveGameStateToSession();

    console.log('Started new game with existing players and cleared scores:', newGameData);
  };

  const handleConfirmNewGame = async () => {
    const gameData = {
      players,
      sprints,
      gameId,
      isFinished: false,
      gameEnded,
      gameEndDialogShown,
    };
    await saveGame(gameData);

    const newGameId = `AppWars-${new Date().toLocaleString().replace(/[/,:\s]/g, '.')}`;
    setGameId(newGameId);
    setGameEnded(false);
    setGameEndDialogShown(false);

    const initialPlayers = Array.from({ length: 3 }, (_, index) => ({
      id: `player${index}`,
      name: getRandomName(),
      scores: Array(2).fill({ acquiredUsers: 0, bonusUsers: 0 }),
    }));
    setPlayers(initialPlayers);
    setSprints([{ index: 0 }, { index: 1 }]);
    setCurrentSprint(null);
    setSnackbarMessage('New game started!');
    setSnackbarOpen(true);

    sessionStorage.removeItem('guestGameState');
    sessionStorage.removeItem('currentGame');
    setOpenNewGameConfirmDialog(false);
    console.log('Started a new game:', { newGameId, initialPlayers, sprints: [{ index: 0 }, { index: 1 }] });
  };

  const handleCancelNewGame = () => {
    setOpenNewGameConfirmDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSprintNavigation = (index) => {
    setCurrentSprint(index);
  };

  const handleBackToSprints = () => {
    setCurrentSprint(null);

    if (!gameEnded) {
      setShouldAssessWinner(true);

      const winner = players.find(player => calculateGameTotal(player.scores) >= 3000000);
      if (winner && !gameEndDialogShown) {
        setGameWinner(winner.name);
        setShowGameEndDialog(true);
        setGameEndDialogShown(true);
      }
    }
    saveGameStateToSession();
    saveGameStateToFirebase();
    console.log('Navigated back to sprints');
  };

  const handleNavigateHome = (event) => {
    event.preventDefault();
    sessionStorage.removeItem('guestGameState');
    sessionStorage.removeItem('currentGame');
    navigate('/');
  };

  const handleScoreChange = (field, value) => {
    // Remove any non-digit characters
    const numericValue = value.replace(/[^0-9]/g, '');
    setDialogValues(prev => ({
      ...prev,
      [field]: numericValue
    }));
  };

  const handleNameChange = (value) => {
    setDialogValues(prev => ({
      ...prev,
      name: value
    }));
  };

  const handleIncrement = (field) => {
    const currentValue = parseInt(dialogValues[field] || '0', 10);
    const newValue = currentValue + 25;
    handleScoreChange(field, newValue.toString());
  };

  const handleDecrement = (field) => {
    const currentValue = parseInt(dialogValues[field] || '0', 10);
    const newValue = Math.max(0, currentValue - 25);
    handleScoreChange(field, newValue.toString());
  };

  const handleDialogSave = async () => {
    if (currentField === 'scores') {
      const acquiredUsersValue = parseInt(dialogValues.acquiredUsers, 10) * 1000;
      const bonusUsersValue = parseInt(dialogValues.bonusUsers, 10) * 1000;

      const updatedPlayers = players.map((player, pIndex) => {
        if (pIndex === currentEditingPlayer) {
          const updatedScores = player.scores.map((score, sIndex) => {
            if (sIndex === currentSprint) {
              return {
                ...score,
                acquiredUsers: acquiredUsersValue,
                bonusUsers: bonusUsersValue
              };
            }
            return score;
          });
          return { ...player, scores: updatedScores };
        }
        return player;
      });

      setPlayers(updatedPlayers);
      
      // Update the game data with the new player scores
      const gameData = {
        players: updatedPlayers,
        sprints,
        gameId,
        isFinished: false,
        gameEnded,
        gameEndDialogShown,
      };

      // Save the updated game data to Firebase
      await saveGame(gameData);
      console.log('Saved updated game data to Firebase:', gameData);
    } else if (currentField === 'name') {
      handlePlayerNameChange(currentEditingPlayer, dialogValues.name);
    }

    setOpenDialog(false);
    saveGameStateToSession();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    saveGameStateToSession();
  };

  const handlePlayerNameClick = (index) => {
    if (gameEnded) return;
    setCurrentEditingPlayer(index);
    setDialogValues({ name: players[index].name });
    setCurrentField('name');
    handleDialogOpen();
  };

  const handleClickOpen = (playerIndex, field) => {
    if (gameEnded) return;
    setCurrentEditingPlayer(playerIndex);
    const player = players[playerIndex];
    if (!player || !player.scores[currentSprint]) {
      return;
    }
    const acquiredUsersValue = player.scores[currentSprint].acquiredUsers || '';
    const bonusUsersValue = player.scores[currentSprint].bonusUsers || '';
    setDialogValues({
      acquiredUsers: acquiredUsersValue ? (acquiredUsersValue / 1000).toString() : '',
      bonusUsers: bonusUsersValue ? (bonusUsersValue / 1000).toString() : '',
    });
    setCurrentField('scores');
    handleDialogOpen();
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
    setNextLocation(null);
  };

  const handleConfirmDialogOk = () => {
    sessionStorage.removeItem('guestGameState');
    sessionStorage.removeItem('currentGame');
    setOpenConfirmDialog(false);
    navigate(nextLocation);
  };

  const handleConfirmDialogCancel = () => {
    setOpenConfirmDialog(false);
    setNextLocation(null);
  };

  const handleNavigateToPastGames = () => {
    saveGameStateToSession();
    saveGameStateToFirebase();
    navigate('/past-games');
    setShouldAssessWinner(true);
  };

  const checkGameEnd = useCallback(() => {
    if (!shouldAssessWinner) return;

    let sortedPlayers = sortPlayersByTotalScore(players);
    let winnerFound = false;

    sortedPlayers.forEach((player, index) => {
      const gameTotal = player.totalScore;
      if (!isExtendedGame && gameTotal >= 3000000 && !winnerFound) {
        setGameWinner(sortedPlayers[0].name);
        setShowGameEndDialog(true);
        setGameEndDialogShown(true);
        winnerFound = true;
      } else if (isExtendedGame && gameTotal >= 5000000 && !winnerFound) {
        setGameWinner(sortedPlayers[0].name);
        setShowGameEndDialog(true);
        setGameEnded(true);
        setGameEndDialogShown(true);
        winnerFound = true;
      }
    });

    setShouldAssessWinner(false);
  }, [players, isExtendedGame, shouldAssessWinner]);

  useEffect(() => {
    checkGameEnd();
  }, [players, checkGameEnd]);

  const handleContinueGame = () => {
    setIsExtendedGame(true);
    setShowGameEndDialog(false);
  };

  const handleEndGame = () => {
    setOpenConfirmEndGameDialog(true);
  };

  const handleConfirmEndGame = async () => {
    const gameData = {
      players,
      sprints,
      gameId,
      isFinished: true, // Set isFinished to true when the game ends
      gameEnded: true,
      gameEndDialogShown: true,
    };
    
    await saveGame(gameData); // Save the game data with isFinished set to true
  
    setShowGameEndDialog(false);
    setGameEnded(true);
    setGameEndDialogShown(true);
    sessionStorage.removeItem('currentGame');
    setSnackbarMessage(`The game has ended. Winner: ${gameWinner}.`);
    setSnackbarOpen(true);
    setOpenConfirmEndGameDialog(false);
  };
  

  const handleCancelEndGame = () => {
    setOpenConfirmEndGameDialog(false);
    setShowGameEndDialog(true);
  };

  const handleCloseWinnerDialog = () => {
    setShowWinnerDialog(false);
  };

  const sortPlayersByTotalScore = (players) => {
    return players
      .map(player => ({
        ...player,
        totalScore: calculateGameTotal(player.scores)
      }))
      .sort((a, b) => b.totalScore - a.totalScore);
  };

  const isMobile = window.innerWidth <= 600;

  return (
    <ThemeProvider theme={theme}>
      <Container className="container">
        <Box display="flex" justifyContent="space-between" alignItems="center">
        </Box>
        {currentSprint === null ? (
          <Box className="table-container">
            <Box className="header-content">
              <Typography variant="h5" component="h1" className="table-header">
                Game: {gameId} {gameEnded && " - GAME ENDED"}
                <Button 
                id="start-new-button" 
                onClick={handleStartNewGame} 
                startIcon={<Add />} 
                >
                Start New Game
              </Button>
              </Typography>

            </Box>

            <GameTotals players={players} />
            <Button onClick={handleAddPlayer} startIcon={<Add />} color="primary">
              Add Player
            </Button>
            <Grid container spacing={2}>
              {sprints.map((sprint, index) => (
                <Grid item xs={12} key={index}>
                  <Box className="sprint-container" onClick={() => handleSprintNavigation(index)}>
                    <Typography variant="h6" className="sprint-header">Sprint {index + 1}</Typography>
                    <TableContainer component={Paper}>
                      <Table className="sprint-table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Player</TableCell>
                            <TableCell align="right">Acquired Users</TableCell>
                            <TableCell align="right">Bonus Users</TableCell>
                            <TableCell align="right">Sprint Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {players.map((player, playerIndex) => (
                            <TableRow key={player.id}>
                              <TableCell component="th" scope="row" onClick={() => handlePlayerNameClick(playerIndex)}>
                                {player.name}
                              </TableCell>
                              <TableCell align="right" className="table-cell" onClick={() => handleClickOpen(playerIndex, 'scores')}>
                                {formatNumber(player.scores[index]?.acquiredUsers || 0)}
                              </TableCell>
                              <TableCell align="right" className="table-cell" onClick={() => handleClickOpen(playerIndex, 'scores')}>
                                {formatNumber(player.scores[index]?.bonusUsers || 0)}
                              </TableCell>
                              <TableCell align="right" className="table-cell">
                                {formatNumber(calculateSprintTotal(player.scores, index))}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Box className="add-sprint-container">
              <Button onClick={handleAddSprint} startIcon={<Add />} color="primary">
                Add Sprint
              </Button>
            </Box>
          </Box>

        ) : (
          <PlayerComponents
            players={players}
            currentSprint={currentSprint}
            handlePlayerNameClick={handlePlayerNameClick}
            handleClickOpen={handleClickOpen}
            handleBackToSprints={handleBackToSprints}
          />
        )}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Edit Player {currentField === 'name' ? 'Name' : 'Scores'}</DialogTitle>
          <DialogContent>
            {currentField === 'name' ? (
              <>
                <DialogContentText>
                  Please enter the new name for the player.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Player Name"
                  type="text"
                  fullWidth
                  value={dialogValues.name}
                  onChange={(e) => handleNameChange(e.target.value)}
                  inputProps={{
                    onFocus: (e) => e.target.select(),
                  }}
                />
              </>
            ) : (
              <>
                <DialogContentText>
                  Please enter the new values for Acquired Users and Bonus Users.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="acquiredUsers"
                  label="Acquired Users"
                  type="text"
                  fullWidth
                  value={dialogValues.acquiredUsers}
                  onChange={(e) => handleScoreChange('acquiredUsers', e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        ,000
                        <IconButton onClick={() => handleDecrement('acquiredUsers')}>
                          <Remove />
                        </IconButton>
                        <IconButton onClick={() => handleIncrement('acquiredUsers')}>
                          <Add />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="dense"
                  id="bonusUsers"
                  label="Bonus Users"
                  type="text"
                  fullWidth
                  value={dialogValues.bonusUsers}
                  onChange={(e) => handleScoreChange('bonusUsers', e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        ,000
                        <IconButton onClick={() => handleDecrement('bonusUsers')}>
                          <Remove />
                        </IconButton>
                        <IconButton onClick={() => handleIncrement('bonusUsers')}>
                          <Add />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDialogSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose}>
          <DialogTitle>Confirm Navigation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Navigating away to the home page will mark the game as done. You can view the game in the Past Game page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDialogCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDialogOk} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showGameEndDialog} onClose={() => setShowGameEndDialog(false)}>
          <DialogTitle>Game Ended</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {isExtendedGame ? `The extended game has ended. Winner: ${gameWinner}.` : `The normal game has ended. Winner: ${gameWinner}.`}
              {isExtendedGame ? '' : ' Do you want to continue to the extended game?'}
            </DialogContentText>
            <Typography variant="h6">Rankings:</Typography>
            <List>
              {sortPlayersByTotalScore(players).map((player, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${index + 1}. ${player.name}`}
                    secondary={`Total Score: ${player.totalScore}`}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            {isExtendedGame ? (
              <Button onClick={handleConfirmEndGame} color="primary">
                OK
              </Button>
            ) : (
              <>
                <Button onClick={handleEndGame} color="primary">
                  End Game
                </Button>
                <Button onClick={handleContinueGame} color="primary">
                  Continue to Extended Game
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>

        <Dialog open={showWinnerDialog} onClose={handleCloseWinnerDialog}>
          <DialogTitle>Winner</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The winner is: {gameWinner}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseWinnerDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openNewGameConfirmDialog} onClose={handleCancelNewGame}>
          <DialogTitle>Start New Game</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to start a new game? This will discard the current game progress.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelNewGame} color="primary">
              No
            </Button>
            <Button onClick={handleConfirmNewGame} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openConfirmEndGameDialog} onClose={handleCancelEndGame}>
          <DialogTitle>Confirm End Game</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to end the game?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelEndGame} color="primary">
              No
            </Button>
            <Button onClick={handleConfirmEndGame} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openIncompleteGameDialog} onClose={() => setOpenIncompleteGameDialog(false)}>
          <DialogTitle>Incomplete Game Found</DialogTitle>
          <DialogContent>
            <DialogContentText>
              An incomplete game was found. Do you want to continue from where you left off or start a new game?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={startNewGame} color="primary">
              Start New Game
            </Button>
            <Button onClick={loadIncompleteGame} color="primary">
              Continue Game
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </Container>
    </ThemeProvider>
  );
};

export default GuestGame;

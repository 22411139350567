import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import logo from '../assets/appwars-logo.svg';
import { AppBar, Toolbar, Button } from '@mui/material';
import theme from './theme'; // Import your custom theme
import './Header.css';

const Header = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="sticky" color="default">
        <Toolbar className="header">
          <div className="header-logo-container">
            <a href="https://theappwars.com" target="_blank" rel="noopener noreferrer">
              <img
                src={logo}
                alt="App Wars"
                className="header-logo"
              />
            </a>
          </div>
          <div className="header-buttons">
            <Button style={{ minWidth: '140px !important' }} color="inherit" onClick={() => navigate('/')}>
              e-Score App guide
            </Button>
            <Button color="inherit" onClick={() => navigate('/guestgame')}>
              Track Scores
            </Button>
            <Button color="inherit" onClick={() => navigate('/past-games')}>
              Past Games
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;

// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  palette: {
    primary: {
      main: '#AADBE1', // Primary gradient color
    },
    secondary: {
      main: '#E0F8D2', // Secondary gradient color
    },
  },
  typography: {
    fontFamily: 'Roboto Condensed, Arial, sans-serif',
    h5: {
      fontWeight: 700,
    },
    body1: {
      color: '#484848', // White text for better contrast
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
        },

        body: {
        },
      },
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { getPastGames } from '../firebaseFunctions';
import './PastGames.css';
import { differenceInDays, format } from 'date-fns';

const PastGames = () => {
  const [pastGames, setPastGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);

  useEffect(() => {
    const fetchPastGames = async () => {
      const games = await getPastGames();
      // Sort games by date in descending order
      const sortedGames = games.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
      setPastGames(sortedGames);
    };

    fetchPastGames();
  }, []);

  const handleGameClick = (game) => {
    console.log("Selected Game:", game); // Log the selected game for debugging
    setSelectedGame(game);
  };

  const handleClose = () => {
    setSelectedGame(null);
  };

  const calculateAndSortTotalScores = (players) => {
    return players.map(player => ({
      ...player,
      totalScore: player.scores.reduce((total, score) => total + (score.acquiredUsers || 0) + (score.bonusUsers || 0), 0)
    })).sort((a, b) => b.totalScore - a.totalScore);
  };

  const formatGameDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) {
      console.warn('Invalid timestamp:', timestamp); // Log the invalid timestamp
      return 'Date Unknown'; // Return a fallback date
    }
  
    const gameDate = new Date(timestamp.seconds * 1000);
    const daysDifference = differenceInDays(new Date(), gameDate);
  
    if (isNaN(gameDate.getTime())) {
      return 'Invalid Date';
    }
  
    if (daysDifference === 0) {
      return 'Today';
    } else if (daysDifference === 1) {
      return 'Yesterday';
    } else if (daysDifference <= 7) {
      return `${daysDifference} days ago`;
    } else {
      return format(gameDate, 'EEE, MMM dd yyyy');
    }
  };

  const formatNumber = (num) => {
    return num.toLocaleString();
  };

  return (
    <Container className="landing-container">
      <Box className="landing-header">
        <Typography variant="h4" component="h1" gutterBottom>
          Past Games
        </Typography>
        <Box>
        <List>
        {pastGames.map((game, index) => (
          <ListItem key={index} onClick={() => handleGameClick(game)} className="past-game-item">
            <ListItemText
              primary={`${game.gameId || 'Game ID Unknown'} (${formatGameDate(game.timestamp)})`}
              secondary={
                <>
                  {game.isFinished ? <span style={{ color: 'red' }}> (Ended)</span> : null}
                  <br /> {/* To separate the "Ended" tag from the player list */}
                  {calculateAndSortTotalScores(game.players).map((player, i) => (
                    <span key={i} style={{ display: 'block' }}>
                      {`${i + 1}. ${player.name} ${formatNumber(player.totalScore)}`}
                    </span>
                  ))}
                </>
              }
              className="past-games-text"
            />
          </ListItem>
        ))}
      </List>

        </Box>
      </Box>
      <Dialog open={!!selectedGame} onClose={handleClose} fullWidth>
        <DialogTitle className="dialog-title">{selectedGame?.uniqueId}</DialogTitle>
        <DialogContent className="dialog-content">
          {selectedGame && (
            <>
              <Typography variant="h6" className="dialog-content-text">Rankings:</Typography>
              <List>
                {calculateAndSortTotalScores(selectedGame.players).map((player, index) => (
                  <ListItem key={index} className="dialog-content-text">
                    <ListItemText
                      primary={`${index + 1}. ${player.name} ${formatNumber(player.totalScore)}`}
                    />
                  </ListItem>
                ))}
              </List>
              <Typography variant="h6" className="dialog-content-text">Players and Scores by Sprint:</Typography>
              <Box>
                {selectedGame.players[0].scores && selectedGame.players[0].scores.length > 0 ? (
                  selectedGame.players[0].scores.map((_, sprintIndex) => (
                    <Box key={sprintIndex} className="sprint-details">
                      <Typography variant="h6" component="div" className="sprint-title">
                        Sprint {sprintIndex + 1}
                      </Typography>
                      <List>
                        {selectedGame.players.map((player, playerIndex) => (
                          <ListItem key={playerIndex} className="dialog-content-text">
                            <ListItemText
                              primary={player.name}
                              secondary={`Acquired Users: ${formatNumber(player.scores[sprintIndex]?.acquiredUsers || 0)}, Bonus Users: ${formatNumber(player.scores[sprintIndex]?.bonusUsers || 0)}`}
                              className="dialog-content-text"
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body1" className="dialog-content-text">
                    No sprints available.
                  </Typography>
                )}
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default PastGames;

import React from 'react';
import config from '../config'; // Adjust the path according to your file structure

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <p>App Version: {config.version}</p>
    </footer>
  );
};

const footerStyle = {
  width: '100%',
  background: '#ffffff',
  textAlign: 'center',
  padding: '10px 0',
};

export default Footer;

import { db } from './firebaseConfig';
import { collection, addDoc, getDocs, doc, getDoc, query, where, setDoc } from 'firebase/firestore';

const generateUniqueId = () => {
  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  const year = now.getFullYear();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  return `AppWars-${month}-${day}-${year}_${hours}:${minutes}`;
};

const getDeviceId = () => {
  let deviceId = localStorage.getItem('deviceId');
  if (!deviceId) {
    deviceId = 'device-' + Math.random().toString(36).substr(2, 9);
    localStorage.setItem('deviceId', deviceId);
  }
  console.log('Using Device ID:', deviceId);
  return deviceId;
};

export const saveGame = async (gameData) => {
  try {
    const deviceId = getDeviceId(); // Retrieve the device ID
    const timestamp = gameData.timestamp || new Date(); // Ensure a timestamp is set
    const gameRef = doc(db, 'games', gameData.gameId);

    const gameDataToSave = {
      ...gameData,
      deviceId, // Add the device ID to the game data
      timestamp, // Ensure timestamp is saved
    };

    console.log('Saving game data with Device ID:', deviceId);
    await setDoc(gameRef, gameDataToSave, { merge: true });
    console.log('Game saved successfully');
  } catch (error) {
    console.error('Error saving game:', error);
  }
};


export const loadGame = async (gameId) => {
  try {
    const gameDoc = await getDoc(doc(db, 'games', gameId));
    if (gameDoc.exists()) {
      console.log('Game data loaded from Firebase:', gameDoc.data());
      return gameDoc.data();
    } else {
      console.log('No game data found for gameId:', gameId);
      return null;
    }
  } catch (error) {
    console.error('Error loading game data from Firebase:', error);
    return null;
  }
};

export const getIncompleteGame = async () => {
  try {
    const deviceId = getDeviceId();
    console.log('Querying with Device ID:', deviceId); // Log the deviceId used

    const q = query(collection(db, 'games'), where('isFinished', '==', false), where('deviceId', '==', deviceId));
    const querySnapshot = await getDocs(q);

    console.log('Query snapshot size:', querySnapshot.size);
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((doc) => {
        console.log('Game doc:', doc.id, 'Data:', doc.data());
      });

      const gameDoc = querySnapshot.docs[0].data();
      console.log('Returning first incomplete game:', gameDoc);
      return gameDoc;
    } else {
      console.log('No incomplete games found');
      return null;
    }
  } catch (error) {
    console.error('Error getting incomplete game:', error);
    return null;
  }
};


export const getPastGames = async () => {
  try {
    const deviceId = getDeviceId();
    const q = query(collection(db, 'games'), where('deviceId', '==', deviceId));
    const querySnapshot = await getDocs(q);
    const games = [];
    querySnapshot.forEach((doc) => {
      games.push(doc.data());
    });
    return games;
  } catch (error) {
    console.error('Error getting past games:', error);
    return [];
  }
};

export const getGameDetails = async (gameId) => {
  try {
    const docRef = doc(db, 'games', gameId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
  } catch (error) {
    console.error('Error getting game details:', error);
    return null;
  }
};

export { db };

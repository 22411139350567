export const formatNumber = (num) => {
  if (isNaN(num)) return '';
  return num.toLocaleString();
};

export const calculateSprintTotal = (scores, sprintIndex) => {
  const sprint = scores[sprintIndex] || { acquiredUsers: 0, bonusUsers: 0 };
  return (sprint.acquiredUsers || 0) + (sprint.bonusUsers || 0);
};

export const calculateGameTotal = (scores) => {
  return scores.reduce((total, score) => total + (score.acquiredUsers || 0) + (score.bonusUsers || 0), 0);
};

export const generateRandomHex = () => {
  return Math.floor(Math.random() * 0xffffff).toString(16).padEnd(6, '0').toUpperCase();
};

export const getRandomName = () => {
  const entrepreneurNames = [
    'Steve Jobs', 'Bill Gates', 'Elon Musk', 'Jeff Bezos', 'Larry Page', 'Sergey Brin',
    'Mark Zuckerberg', 'Larry Ellison', 'Michael Dell', 'Warren Buffett',
    'Richard Branson', 'Oprah Winfrey', 'Sheryl Sandberg', 'Marissa Mayer', 'Meg Whitman', 'KAREN'
  ];
  const randomIndex = Math.floor(Math.random() * entrepreneurNames.length);
  return entrepreneurNames[randomIndex];
};

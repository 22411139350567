import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import GuestGame from './components/GuestGame';
import PastGames from './components/PastGames';
import GameDetails from './components/GameDetails';  // Import the new component
import Header from './components/Header';
import Footer from './components/Footer'; // adjust the path according to your file structure


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/guestgame" element={<GuestGame />} />
        <Route path="/past-games" element={<PastGames />} />
        <Route path="/game-details/:gameId" element={<GameDetails />} /> {/* Add the new route */}
      </Routes>
    </Router>
  );
}

export default App;
